import { combineReducers } from "redux";

import customiseReducer from "./customise/customiseReducer";
import dataListReducer from "./datalist/dataListReducer";

const rootReducer = combineReducers({
  customise: customiseReducer,
  dataList: dataListReducer,
});

export default rootReducer;
