import { Link } from "react-router-dom";

import { Row, Col, Button } from "antd";

import img from "../../../assets/images/pages/error/404.svg";
import themeConfig from "../../../configs/themeConfig";

export default function Error401() {
  return (
    <Row className="hp-bg-color-primary-4 hp-bg-color-dark-90 hp-text-center">
      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100" align="middle" justify="center">
          <Col>
            <div className="hp-position-relative hp-mt-sm-0 hp-mt-64 hp-mb-32">
              <img
                className="hp-position-relative hp-d-block hp-m-auto"
                src={img}
                width={400}
                alt="401"
              />
            </div>

            <h1 className="hp-error-content-title hp-mb-sm-0 hp-mb-8 hp-font-weight-500">
              401 - Unauthorized
            </h1>

            <p className="hp-mb-32 hp-p1-body">
              Kamu belum terautentikasi untuk mengakses halaman ini
            </p>

            <Link to="/login">
              <Button type="primary">Refresh</Button>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="hp-py-24">
        <p className="hp-mb-0 hp-badge-text">
          Copyright ©{themeConfig.year} {themeConfig.company}, All rights
          Reserved
        </p>
      </Col>
    </Row>
  );
}
