export const api_base_url = process.env.REACT_APP_BASE_URL;

export const API_URL = {
  // Authentication
  login: api_base_url + "/auth",
  signup: api_base_url + "/signup",
  forgotOTP: api_base_url + "/auth/otp",
  forgotCredentials: api_base_url + "/auth/credential",
  verification: api_base_url + "/verification",
  verify: api_base_url + "/auth/verification",

  dashboard: api_base_url + "/dashboard",
  statistic: api_base_url + "/report/statistic",
  appointment: api_base_url + "/appointment",
  periksa: api_base_url + "/encounter",
  checkout: api_base_url + "/checkout",

  admin: api_base_url + "/admin",
  doctor: api_base_url + "/dokter",
  patient: api_base_url + "/pasien",
  medicRecord: api_base_url + "/medic-record",
  acceptanceForm: api_base_url + "/acceptance-form",

  medicine: api_base_url + "/obat",
  invoice: api_base_url + "/invoices",
  payment: api_base_url + "/payment",

  spending: api_base_url + "/pengeluaran",
  visitorReport: api_base_url + "/report/visitation-chart",
  discountReport: api_base_url + "/report/discount-chart",
  dinkesReport: api_base_url + "/report/dinkes",
  financeReport: api_base_url + "/report/financial",
  dailyReport: api_base_url + "/report/daily",
  adjustmentReport: api_base_url + "/report/adjustment",
  jasmedReport: api_base_url + "/report/medicalfees",
  packageReport: api_base_url + "/report/subscription",

  // Settings
  printer: api_base_url + "/printer",
  price: api_base_url + "/tindakan",
  price_icd9: api_base_url + "/tindakan-icd9",
  diagnose: api_base_url + "/diagnosis",
  paymentMethod: api_base_url + "/payment-methods",
  openingHours: api_base_url + "/operational-hours",
  aboutClinic: api_base_url + "/clinic",
  package: api_base_url + "/package",
  changePassword: api_base_url + "/change-password",

  //Profile
  profile: api_base_url + "/profile",

  // Export Excel :
  excel: api_base_url + "/excel",

  // Uploader
  uploader: api_base_url + "/uploader",

  // Integration
  satusehat: api_base_url + "/satusehat",
};
