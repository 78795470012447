import React, { useEffect, useState } from "react";

import { Col, Row } from "antd";
import { Calendar, Home, Paper, People, User } from "react-iconly";
import { Link } from "react-router-dom";

export default function BottomMenu(props) {
  const { active } = props;

  return (
    <Row className="hp-app-menu-layout hp-m-0 hp-bg-color-black-10 hp-bg-color-dark-90">
      <Col flex="1" className="hp-text-center">
        <Link
          to="/dashboard"
          className={
            active === "home"
              ? "hp-app-menu hp-text-color-primary-1"
              : "hp-app-menu hp-text-color-grey-1 hp-text-color-dark-10 "
          }
        >
          <Home set="curved" />
          <span className="hp-d-block">Beranda</span>
        </Link>
      </Col>
      <Col flex="1" className="hp-text-center">
        <Link
          to="/booking/data"
          className={
            active === "booking"
              ? "hp-app-menu hp-text-color-primary-1"
              : "hp-app-menu hp-text-color-grey-1 hp-text-color-dark-10 "
          }
        >
          <Calendar set="curved" />
          <span className="hp-d-block">Booking</span>
        </Link>
      </Col>
      <Col flex="1" className="hp-text-center">
        <Link
          to="/patient/data"
          className={
            active === "patient"
              ? "hp-app-menu hp-text-color-primary-1"
              : "hp-app-menu hp-text-color-grey-1 hp-text-color-dark-10 "
          }
        >
          <People set="curved" />
          <span className="hp-d-block">Pasien</span>
        </Link>
      </Col>

      <Col flex="1" className="hp-text-center">
        <Link
          to="/report/data"
          className={
            active === "report"
              ? "hp-app-menu hp-text-color-primary-1"
              : "hp-app-menu hp-text-color-grey-1 hp-text-color-dark-10 "
          }
        >
          <Paper set="curved" />
          <span className="hp-d-block">Laporan</span>
        </Link>
      </Col>

      <Col flex="1" className="hp-text-center">
        <Link
          to="/profile"
          className={
            active === "profile"
              ? "hp-app-menu hp-text-color-primary-1"
              : "hp-app-menu hp-text-color-grey-1 hp-text-color-dark-10 "
          }
        >
          <User set="curved" />
          <span className="hp-d-block">Profil</span>
        </Link>
      </Col>
    </Row>
  );
}
