import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import Logo from "../../../../assets/images/logo/logo.webp";
import LogoDark from "../../../../assets/images/logo/logo-dark.webp";

export default function MenuLogo(props) {
  const customise = useSelector((state) => state.customise);

  return (
    <div className="hp-header-logo hp-d-flex hp-align-items-end">
      <Link to="/dashboard" onClick={props.onClose}>
        {customise.theme == "light" ? (
          <img className="hp-logo" src={Logo} alt="Logo" />
        ) : (
          <img className="hp-logo" src={LogoDark} alt="Logo" />
        )}
      </Link>
    </div>
  );
}
